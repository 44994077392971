import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export interface LoadingProps {
  invisible?: boolean;
  backdropClassName?: string;
}

const Loading = (props: LoadingProps): JSX.Element => {
  return (
    <Backdrop
      open={true}
      invisible={props.invisible}
      data-testid="app-loading-spinner"
      className={props.backdropClassName}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loading;
