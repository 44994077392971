//@ts-nocheck
import {
  AuthenticatorProps,
  theme,
  WithAuthenticator,
  WithAuthenticatorProps,
} from '@konecorp/ui-library'
import { Box, CssBaseline } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import Context from '../../context'
import Loading from '../../components/Loading'
import LandingPage from '../LandingPage'
import ProfilePage from '../ProfilePage'
import Qualification from '../Qualification'
import AdminScreen from '../AdminScreen'

const APP_URL = process.env.REACT_APP_IAPP_URL || ''

const useStyles = makeStyles()((theme) => ({
  loadingBackdrop: {
    zIndex: 1500,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    '& span.Mui-selected': {
      fontSize: 12,
    },
  },
}))

const KoneEmployeeApp = (
  props: PropsWithChildren<WithAuthenticatorProps>,
): JSX.Element => {
  const {
    authState: { isAuthenticated ,user},
    updateAuthState,
    updateAcquireToken,
    isLoading,
  } = useContext(Context)
  const [email, setEmail] = useState()
  const regex = /^[a-zA-Z0-9._%+-]+@kone\.com$/
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles(theme)
  // const [userRole] = useGetCurrentUserRole();
  const redirectUrl = window.sessionStorage.getItem('redirectUrl')

  const [referrerUrl, setReferrerUrl] = useState(
    window.sessionStorage.getItem('referrerUrl'),
  )

  /* This needed because Azure AD perform redirection and we need to know
     what was an URL user want to access
  */
  useEffect(() => {
    const { pathname, search } = location

    if (pathname.length > 1 && !isAuthenticated) {
      window.sessionStorage.setItem('redirectUrl', `${pathname}${search}`)
    }
    if (pathname.length === 1 && isAuthenticated) {
      if (redirectUrl) {
        history.push(redirectUrl)
      }
    }
    if (pathname.length > 1 && isAuthenticated) {
      window.sessionStorage.removeItem('redirectUrl')
    }
  }, [location, isAuthenticated, history, redirectUrl])

  useEffect(() => {
    const updateLoginData = async (): Promise<void> => {
      try {
        const authResponse = await props.acquireToken(true)
        updateAcquireToken(() => props.acquireToken) //store the function

        // The authResponse (try to call refresh token) can fail in slow network or offline mode
        // which is the reason of ? and passing in empty string. However, this following function, updateGraphData
        // is the one who set authState.isAuthenticated to true or false, if the user already logged-in and there is a cache
        // it will result in a true to isAuthenticated, if there is NO cache aka user never login before, it will force user to login like usual
        // read the updateGraphData detail implementation and service-worker.js for more information
        await props.updateGraphData(authResponse?.accessToken || '')

        const authState = props.getAuthState()
        console.log("=authState",authState)
        updateAuthState(authState)

        setEmail(authState.user.username)
      } catch (error) {
        console.error('updateLoginData', error)
      }
    }

    if (
      !referrerUrl &&
      document.referrer &&
      process.env.REACT_APP_IAPP_URL !== document.referrer
    ) {
      const url = document.referrer

      setReferrerUrl(url)
      window.sessionStorage.setItem('referrerUrl', url)
    }
    if (!isAuthenticated) updateLoginData()
  }, [isAuthenticated, referrerUrl, props, updateAuthState, updateAcquireToken])
  return (
    <>
      {(isLoading || !isAuthenticated) && (
        <Loading backdropClassName={classes.loadingBackdrop} />
      )}
      {isAuthenticated && (
        <>
          <Box pb={11}>
            <CssBaseline />
            <main className={classes.content}>
              <Switch>
                <Route path="/profile" exact component={ProfilePage} />
                <Route path="/qualification" exact component={Qualification} />
                <Route path="/" exact component={LandingPage} />
                {regex.test(email) ? (
                  <Route path="/admin" exact component={AdminScreen} />
                ) : null}
              </Switch>
            </main>
          </Box>
        </>
      )}
    </>
  )
}
export { KoneEmployeeApp as PureComponent }
export default WithAuthenticator(KoneEmployeeApp, {
  clientId: '92480ffe-d1fa-434d-96a5-a1a1d635d0a2',
  authority:
    'https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50',
  redirectUri: APP_URL,
  validateAuthority: true,
  postLogoutRedirectUri: APP_URL,
  navigateToLoginRequestUrl: false,
} as AuthenticatorProps)
