import { createContext } from 'react'
import { AuthStateProps, AcquireTokenFunction } from '@konecorp/ui-library'

export type ErrorMessage = {
  message: string
  error: unknown
}

export interface ContextState {
  authState: AuthStateProps
  isLoading: boolean
  acquireToken?: AcquireTokenFunction
  errorMessage: ErrorMessage | null
  updateAuthState: (authState: AuthStateProps) => void
  updateIsLoading: (status: boolean) => void
  updateAcquireToken: (newFunction: () => AcquireTokenFunction) => void
  updateErrorMessage: (errorMessage: ErrorMessage | null) => void
}

export default createContext({} as ContextState)
