import React from 'react'

import { ThemeProvider } from '@mui/material/styles'

import { theme as KoneTheme, WithAuthenticatorProps } from '@konecorp/ui-library'

import GlobalState from '../../context/globalState'

import KoneEmployeeApp from '../KoneEmployeeApp'
// import { createTheme } from '@material-ui/core'

const props = {} as WithAuthenticatorProps
// const theme= createTheme({})


const App = (): JSX.Element => {
  return (
    <GlobalState>
      <ThemeProvider theme={KoneTheme}>
      {//@ts-ignore
        <KoneEmployeeApp {...props} ></KoneEmployeeApp>
      }
      </ThemeProvider>
    </GlobalState>
  )
}

export default App
