import React, { useState, ReactNode } from 'react'
import { AuthStateProps, AcquireTokenFunction } from '@konecorp/ui-library'

import Context, { ContextState, ErrorMessage } from './index'

type ReactProps = {
  children: ReactNode
}

export default ({ children }: ReactProps): JSX.Element => {
  const authData = {
    isAuthenticated: false,
  } as AuthStateProps

  const [authState, updateAuthState] = useState<AuthStateProps>(authData)

  const [isLoading, updateIsLoading] = useState<boolean>(false)
  const [errorMessage, updateErrorMessage] = useState<ErrorMessage | null>(null)

  const [acquireToken, updateAcquireToken] = useState<
    AcquireTokenFunction | undefined
  >()

  const state: ContextState = {
    authState,
    isLoading,
    acquireToken,
    errorMessage,
    updateAuthState,
    updateIsLoading,
    updateAcquireToken,
    updateErrorMessage,
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
