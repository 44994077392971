import React, { useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  ListItem,
  List,
} from '@mui/material'

interface Proptype {
  handleCloseDialog: () => void
  openDialog:boolean
}
export default function AddNewDialog({ handleCloseDialog ,openDialog}: Proptype) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText>Add New:</DialogContentText>
          <List>
            <ListItem> -Subcontractor company? </ListItem>
            <ListItem> -Supervisor </ListItem>
            <ListItem> -Fitter </ListItem>
            <ListItem> -Qualification </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
