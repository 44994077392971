//@ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import logo from '../../logo.svg'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import axios, { AxiosRequestConfig } from 'axios'
import { useHistory } from 'react-router-dom'
import { useGetToken } from '../../hooks/useGetToken'
import Context from '../../context'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#e8eaf6',
  },
  header: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  headerInfo: {
    width: '100%',
    padding: '16px 8px',
  },
  logoInfo: {
    display: 'inline-flex',
  },
  qualificationsTable: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    textAlign: 'left',
    marginLeft: 'theme.spacing(1)',
    fontSize: 20,
  },
  tableRow: {
    borderBottom: '1px solid #ccc',
    backgroundColor: '#F5F5F5',
    fontSize: 80,
  },
  tableCell: {
    padding: theme.spacing(1),
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  tableCell1: {
    padding: '16px 0px',
  },
  tableRow1: {
    color: 'orange',
  },
  expired: {
    color: '#ef5350',
  },
  expiringSoon: {
    color: 'orange',
  },
  valid: {
    color: '#4caf50',
  },
  idButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#304ffe',
    color: theme.palette.secondary.contrastText,
    borderRadius: '8px',
    bottom: 0,
    textAlign: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: '20px',
  },
  buttonInfo: {
    textAlign: 'center',
  },
  userInfo: {
    display: 'inline-flex',
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  ml3: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
}))

const Qualification = () => {
  const {
    authState: {user}
  } = useContext(Context)
  
  const [getToken] = useGetToken()
  const { classes } = useStyles()
  const history = useHistory()

  const handleNavigation = () => {
    history.push('/profile')
  }
  const [data, setData] = useState([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const token = await getToken()
      const uri =
      `https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/installers/view?emailId=${user.username}`
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      try {
        const response = await axios.get(uri, config)
        console.log("12",response.data.installer[0])
        console.log("123",response.data.installer[0].qualifications)
        setData(response.data.installer[0].qualifications)
        setLoading(false)
      } catch (err) {
        setError('Failed to fetch data')
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>{error}</div>
  }
console.log('data1',data.method)
console.log('data2',data.safety)
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.header}>
          <Grid container spacing={3} className={classes.headerInfo}>
            <Grid item xs>
              <div className={classes.logoInfo}>
                <img src={logo} alt="loading" />
                <Typography
                  variant="h5"
                  style={{ whiteSpace: 'nowrap', marginLeft: '8px' }}
                >
                  Safety Passport
                </Typography>
              </div>
            </Grid>

            <Grid item xs>
              <div className={classes.userInfo}>
                <AccountCircleIcon className={classes.ml1} />
                <Typography variant="subtitle1" className={classes.ml1}>
                {user?.username}
                </Typography>
                <KeyboardArrowDownIcon className={classes.ml1} />
              </div>
            </Grid>
          </Grid>
        </div>

        <TableContainer>
          <Table
            sx={{ minWidth: 300, maxWidth: 450 }}
            component={Paper}
            className={classes.qualificationsTable}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  <span className={classes.ml1}>Qualification</span>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <span className={classes.ml1}>Expiration</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                
                // @ts-ignore
                data.safety.map((qual, index) => (
               
                  <TableRow
                    key={index}
                    className={classes.tableRow}
                    style={
                      index % 2
                        ? { background: 'white' }
                        : { background: '#F5F5F5' }
                    }
                  >
                    <TableCell className={classes.tableCell}>
                      {qual.title}
                    </TableCell>
                    <TableCell className={classes.tableCell1}>
                      {qual.isExpired ? (
                        <WarningRoundedIcon className={classes.expired} />
                      ) : (
                        <CircleRoundedIcon
                          className={classes.valid}
                          fontSize="small"
                        />
                      )}
                      <span className={classes.ml3}>
                        {' '}
                        {qual.expiry}
                      </span>
                    </TableCell>
                  </TableRow>
                ))
                      }
               { data.method.map((qual, index) => (
                  <TableRow
                    key={index}
                    className={classes.tableRow}
                    style={
                      index % 2
                        ? { background: 'white' }
                        : { background: '#F5F5F5' }
                    }
                  >
                    <TableCell className={classes.tableCell}>
                      {qual.title}
                    </TableCell>
                    <TableCell className={classes.tableCell1}>
                      {qual.isExpired ? (
                        <WarningRoundedIcon className={classes.expired} />
                      ) : (
                        <CircleRoundedIcon
                          className={classes.valid}
                          fontSize="small"
                        />
                      )}
                      <span className={classes.ml3}>
                        {' '}
                        {qual.expiry}
                      </span>
                    </TableCell>
                  </TableRow>
                ))
                  }
              
              
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.buttonInfo}>
          <Button
            className={classes.idButton}
            variant="contained"
            onClick={handleNavigation}
          >
            Open my ID card
            <QrCodeScannerRoundedIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
export default Qualification
