import React, { useContext } from 'react'
import { AppBar, Toolbar, Typography, IconButton, Avatar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { makeStyles } from 'tss-react/mui'
import logo from '../../logo.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Context from '../../context'

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const Header: React.FC = () => {
  const {
    authState: {user}
  } = useContext(Context)
  const [open, setOpen] = React.useState(false)

  const { classes } = useStyles()
  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <img src={logo} alt="loading" />

          <Typography variant="h6" className={classes.title}>
            Safety Passport - Supervisor's Hub
          </Typography>
          <IconButton color="inherit">
            <AccountCircleIcon />
          </IconButton>
          <Typography>{user?.username}</Typography>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  )
}
export default Header
