//@ts-nocheck
import React, { useEffect, useState } from 'react'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Alert
} from '@mui/material'
import axios from 'axios'
import { useGetToken } from '../../hooks/useGetToken'

const InstallerForm = ({ openForm, onClose, onUpdateInstallers }) => {
  const [getToken] = useGetToken()
  const [supervisorCodes, setSupervisorCodes] = useState([])
  const [countryList, setCountryList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [installerRoleList, setInstallerRoleList] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    companyCode: '',
    countryCode: '',
    firstName: '',
    installerEmail: '',
    installerRoleCode: '',
    lastName: '',
    qualification: {
      safety: [
        {
          title: 'Access&Egress (Main/Practical)',
          status: 'false',
          expiry: '21/10/2024',
        },
        {
          title: 'Electrical Safety Refresher',
          status: 'false',
          expiry: '21/10/2024',
        },
        {
          title: 'Fall Prevention',
          status: 'false',
          expiry: '21/10/2024',
        },
        {
          title: 'H&S Awareness',
          status: 'false',
          expiry: '21/10/2024',
        },
        {
          title: 'Material Handling',
          status: 'false',
          expiry: '21/10/2024',
        },
       
      ],
      method: [
        { title: 'TAKE 2', status: 'false', expiry: '21/10/2024' },
        { title: 'TAKE 5 Global', status: 'false', expiry: '21/10/2024' },
        { title: ' Under the Hook Lifting and Slinging', status: 'false', expiry: '21/10/2024' },
      ],
    },
    status: true,
    supervisorCode: '',
    startDate: '',
    endDate: '',
  })

  const handleQualificationChange = (category, title) => {
    setFormData((prevData) => {
      const updatedQualifications = {
        ...prevData.qualification,
        [category]: prevData.qualification[category].map((qual) =>
          qual.title === title
            ? { ...qual, status: qual.status === 'false' ? 'true' : 'false' }
            : qual,
        ),
      }
      return { ...prevData, qualification: updatedQualifications }
    })
  }
  const validateForm = () => {
    const safetySelected = formData.qualification.safety.some(qual => qual.status === 'true');
    const methodSelected = formData.qualification.method.some(qual => qual.status === 'true');

    if (!safetySelected || !methodSelected) {
      setError('Please select at least one training from both Safety and Method qualifications.');
      return false;
    }

    setError('');
    return true;
  };


  const handleChange = (e) => {
    const { name, value, type } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? e.target.checked : value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const today = new Date()
    const startDate = new Date(formData.startDate)
   

    // Get the date one month ago
    const oneMonthAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate(),
    )

    // Validation for start date
    if (startDate > today || startDate < oneMonthAgo) {
      alert('Start date must be either today or from the previous month.')
      return
    }

    // Validation for end date (8 days gap from start date)
    const endDate = new Date(formData.endDate)
    const dateDiff = (endDate - startDate) / (1000 * 60 * 60 * 24) // Difference in days

    if (dateDiff < 28) {
      alert('End date must be at least 28 days after the start date.')
      return
    }
    const qualification={
      safety: [
        {
          title: 'Access&Egress (Main/Practical)',
          status: 'false',
          expiry: formData.endDate,
        },
        {
          title: 'Electrical Safety Refresher',
          status: 'false',
          expiry: formData.endDate,
        },
        {
          title: 'Fall Prevention',
          status: 'false',
          expiry: formData.endDate,
        },
        {
          title: 'H&S Awareness',
          status: 'false',
          expiry: formData.endDate,
        },
        {
          title: 'Material Handling',
          status: 'false',
          expiry: formData.endDate,
        },
       
      ],
      method: [
        { title: 'TAKE 2', status: 'false', expiry: formData.endDate },
        { title: 'TAKE 5 Global', status: 'false', expiry: formData.endDate },
        { title: ' Under the Hook Lifting and Slinging', status: 'false', expiry: formData.endDate },
      ],
    }
    setFormData({...formData,qualification:qualification})
    

    // Proceed with the form submission if validation passes
    if (validateForm()) {
    submitForm(e)
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()

    const token = await getToken()
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      const uri =
        'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/installers/add'
      const response = await axios.post(uri, formData, config)
      if (response.data) {
        const updatedResponse = await axios.get(
          'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/installers',
          config,
        )
        onUpdateInstallers(updatedResponse.data)
        if(updatedResponse.data)
        setSuccessMessage('User added successfully!')
        setShowAlert(true) // Show alert

        onClose()
      }

      // Reset form after submission
      setFormData({
        companyCode: '',
        countryCode: '',
        firstName: '',
        installerEmail: '',
        installerRoleCode: '',
        lastName: '',
        qualification: {
          safety: [
            {
              title: 'Access&Egress (Main/Practical)',
              status: 'false',
              expiry: '21/10/2024',
            },
            {
              title: 'Electrical Safety Refresher',
              status: 'false',
              expiry: '21/10/2024',
            },
            {
              title: 'Fall Prevention',
              status: 'false',
              expiry: '21/10/2024',
            },
            {
              title: 'H&S Awareness',
              status: 'false',
              expiry: '21/10/2024',
            },
            {
              title: 'Material Handling',
              status: 'false',
              expiry: '21/10/2024',
            },
           
          ],
          method: [
            { title: 'TAKE 2', status: 'false', expiry: '21/10/2024' },
            { title: 'TAKE 5 Global', status: 'false', expiry: '21/10/2024' },
            { title: ' Under the Hook Lifting and Slinging', status: 'false', expiry: '21/10/2024' },
          ],
        },
        status: true,
        supervisorCode: '',
        startDate: '',
        endDate: '',
      })

      onClose()
    } catch (error) {
      console.error('Error submitting form:', error)
      setSuccessMessage('Error adding user. Please try again.')
    }
  }

  const fetchCountryData = async () => {
    const token = await getToken() 
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      const response = await axios.get(
        'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/countries',
        config,
      )
      const countries = response.data
      setCountryList(countries)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const fetchRolesData = async () => {
    const token = await getToken() 
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      const response = await axios.get(
        'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/roles',
        config,
      )
      const installerRoles = response.data
      setInstallerRoleList(installerRoles)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const fetchCompanyData = async () => {
    const token = await getToken() // Assuming you have a function to get the token
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      const response = await axios.get(
        'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/company/list',
        config,
      )

      const companies = response.data
      setCompanyList(companies)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchSupervisors = async () => {
    const token = await getToken()
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
      const getResponse = await axios.get(
        'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/tfs/list',
        config,
      )
      setSupervisorCodes(getResponse.data)
    } catch (error) {
      console.error('Error fetching supervisors:', error)
    }
  }
  useEffect(() => {
    fetchCountryData()
    fetchCompanyData()
    fetchRolesData()
  }, [])
  useEffect(() => {
    if (openForm) {
      fetchSupervisors()
    }
  }, [openForm])

  
  return (
    <Dialog open={openForm} onClose={onClose}  maxWidth="sm">
      <DialogTitle>Installer Registration</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Country Name</InputLabel>
            <Select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
            >
              {countryList.map((country) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Company Name</InputLabel>
            <Select
              name="companyCode"
              value={formData.companyCode}
              onChange={handleChange}
            >
              {companyList.map((company) => (
                <MenuItem key={company.company_id} value={company.company_id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Installer Email"
            type="email"
            name="installerEmail"
            value={formData.installerEmail}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Installer Role</InputLabel>
            <Select
              name="installerRoleCode"
              value={formData.installerRoleCode}
              onChange={handleChange}
            >
              {installerRoleList.map((role) => (
                <MenuItem key={role.role_id} value={role.role_id}>
                  {role.role_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <h3>Safety Qualifications</h3>
            {formData.qualification.safety.map((qual) => (
              <FormControlLabel
                key={qual.title}
                control={
                  <Checkbox
                    checked={qual.status === 'true'}
                    onChange={() =>
                      handleQualificationChange('safety', qual.title)
                    }
                    value={qual.title}
                  />
                }
                label={`${qual.title}`}
              />
            ))}
          </div>

          <div>
            <h3>Method Qualifications</h3>
            {formData.qualification.method.map((qual) => (
              <FormControlLabel
                key={qual.title}
                control={
                  <Checkbox
                    checked={qual.status === 'true'}
                    onChange={() =>
                      handleQualificationChange('method', qual.title)
                    }
                    value={qual.title}
                  />
                }
                label={`${qual.title}`}
              />
            ))}
                  {error && <Typography color="error">{error}</Typography>}
          </div>

          <FormControlLabel
            control={
              <Checkbox
                name="status"
                checked={formData.status}
                onChange={handleChange}
              />
            }
            label="Active"
          />

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Supervisor Name</InputLabel>
            <Select
              name="supervisorCode"
              value={formData.supervisorCode || ''}
              onChange={(e) => {
                handleChange(e)
              }}
            >
              {supervisorCodes.map((supervisor) => (
                <MenuItem key={supervisor.tfs_id} value={supervisor.tfs_id}>
                  {supervisor.first_name} {supervisor.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            label="Start Date"
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="End Date"
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
        {showAlert && (
          <Alert severity="success" style={{ marginBottom: '16px' }}>
            {successMessage}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default InstallerForm
