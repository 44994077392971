import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { WithAuthenticatorProps } from '@konecorp/ui-library'
import App from './containers/App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const props = {} as WithAuthenticatorProps
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
