import React from 'react'
import { Box } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { css } from '@emotion/react'

interface ImageWithTickProps {
  src: string
  alt: string
}
const ImageWithTick: React.FC<ImageWithTickProps> = ({ src, alt }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={src}
        alt={alt}
        style={{
          width: 230,
          height: 230,
          marginLeft: '24px',
          marginTop: '18px',
        }}
      />
      <CheckCircleIcon
        sx={{
          position: 'absolute',
          top: 4,
          left: 5,
          color: 'green',
          backgroundColor: 'white',
          borderRadius: '50%',
        }}
        fontSize="large"
      />
    </Box>
  )
}
export default ImageWithTick
