import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Icon,
  Box,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import BarChartIcon from '@mui/icons-material/BarChart'
import { makeStyles } from 'tss-react/mui'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone'
import AddNewDialog from '../AddNewDialog'

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    border:'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))
interface Proptype{
  handleOpenDialog:()=>void
}
const SubHeader = ({handleOpenDialog}:Proptype) => {
  const { classes } = useStyles()
  
  return (
    <div className={classes.root}>
        
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Icon  sx={{marginLeft:40,color:'#0000FF'}}>
            <ViewListTwoToneIcon sx={{fontSize:30}}/>
          </Icon>
          <Icon color="inherit">
            <BarChartIcon sx={{fontSize:40}}/>
          </Icon>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // Aligns the icons to the right
              alignItems: 'center',
              padding: '8px', // Adjust padding as needed
              width: '100%', // Take up full width of the container
            border:'none'
            }}
          >
            <Icon color="inherit">
              <SearchIcon sx={{fontSize:30}}/>
            </Icon>
            <Icon color="inherit" >
              <FilterAltOutlinedIcon sx={{fontSize:30}} />
            </Icon>
            <IconButton color="inherit" onClick={handleOpenDialog}>
            <AddBoxOutlinedIcon sx={{fontSize:30}} />
              
            </IconButton>
           
            <Icon color="inherit">
              <FileUploadOutlinedIcon sx={{fontSize:30}}/>
            </Icon>
          </Box>
        </Toolbar>
      </AppBar>
     
    </div>
  )
}
export default SubHeader
