//@ts-nocheck
import React, {useContext, useEffect, useState } from 'react'
import { Card, CardContent, Typography, Grid } from '@mui/material'
import { QRCode } from 'react-qrcode-logo'
import { makeStyles } from 'tss-react/mui'
import axios, { AxiosRequestConfig } from 'axios'
import { useGetToken } from '../../hooks/useGetToken'
import ImageWithTick from '../../components/ImageWithTick'
import Context from '../../context'

interface InstallerData {
  first_name: string
  last_name: string
  company_id: string
  role_id: string
  installer_id: string
}

const useStyles = makeStyles()(() => ({
  content: {
    padding: '20px',
    marginTop: '20px',
    width: '95%',
  },
  headerInfo: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left',
  },
  container: {
    height: '100%',
    margin: '0',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginLeft: '20px !important',
  },
  statusContainer: {
    display: 'inline-flex',
    position: 'relative',
    left: '20%',
    top: '-5%',
    zIndex: 100,
  },
  statusText: {
    display: 'inline',
    marginTop: '-10px',
    marginLeft: '-5px',
  },
  avatarMargin: {
    marginLeft: '-40px !important',
    marginTop: '10px !important',
  },
}))

const ProfilePage: React.FC = () => {
  const {
    authState: {user}
  } = useContext(Context)
console.log("=user",user)
  const [data, setData] = useState<InstallerData | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [photo, setPhoto] = useState<string | null>(null)
  const { classes } = useStyles()
  const [getToken] = useGetToken()
  
  const fetchData = async () => {
    const token = await getToken()
    const uri =
      `https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/installers/view?emailId=${user.username}`
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }

    try {
      const response = await axios.get(uri, config)
      setData(response.data.installer[0])
    } catch (err) {
      console.error(err)
      setError('Failed to fetch data')
    } finally {
      setLoading(false)
    }
  }

  const handleImagedata = async () => {
    const token = await getToken()
    const uri =
      'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/attachments/download'
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
    const payload = {
      attachments: [
        {
          filename: 'profile_image.jpg',
          location: 'profile_image.jpg',
        },
      ],
    }

    try {
      const response = await axios.post(uri, payload, config)
      setPhoto(response.data.imageUrl[0].signedUrl)
    } catch (err) {
      console.error(err)
      setError('Failed to fetch image data')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
    handleImagedata()
  }, [])

  if (loading) return <p>Loading...</p>
  if (error) return <p>{error}</p>
  if (!data) return null

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Card sx={{ maxWidth: 345, padding: 2 }}>
            <Grid container spacing={2}>
              <Typography
                variant="h6"
                component="div"
                className={classes.header}
              ></Typography>
              <Grid item xs className={classes.headerInfo}>
                <QRCode
                  value={`${data.first_name} ${data.last_name} - KONE - ${data.role_id}`}
                  size={226}
                  id="qrcode"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={`${classes.headerInfo} ${classes.avatarMargin}`}
              >
                <div className={classes.statusContainer}>
                  <p className={classes.statusText}>Active</p>
                </div>

                <ImageWithTick
                  src={photo as string}
                  alt={`${data.firstName}'s profile`}
                />
              </Grid>
              <Grid item xs={12} className={classes.headerInfo}>
                <CardContent>
                  <Typography variant="body2" color="text.primary">
                    Name: {data.firstName} {data.lastName}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Company: {data.companyName}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Role: {data.roleName}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Pass ID: {data.installerId}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
