import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

export interface LandingPageProps {
  className?: string
}

const LandingPageHeadings = {
  Text1: 'Izzy Installer: Safety Passport mock up',
  Text2: 'Simon Supervisor : Safety Passport mock up',
}
const useStyles = makeStyles()((theme) => ({
   button: {
    borderColor: '#007FFF',
    width: '100%',
  },
  paragraph: {
    whiteSpace: 'nowrap',
  },
  
}))

const LandingPage = (): JSX.Element => {
  const {classes} = useStyles()
  const history = useHistory()

  const handleNavigation = () => {
    history.push('/qualification')
  }

  return (
    <Container maxWidth="xs">
      <Box display="flex" sx={{ p: 6, mt: 10 }}>
        <Stack spacing={{ xs: 4, sm: 2, md: 2 }}>
          <p className={classes.paragraph}>{LandingPageHeadings.Text1}</p>

          <Button
            id="landingPage-button"
            type="submit"
            variant="outlined"
            size="large"
            name="StANDALONE"
            className={classes.button}
            onClick={handleNavigation}
          >
            STANDALONE
          </Button>
          <Button
            id="landingPage-iesbutton"
            color="primary"
            type="submit"
            variant="outlined"
            size="large"
            name="AS PART OF IES"
            className={classes.button}
          >
            AS PART OF IES
          </Button>
        </Stack>
      </Box>
      <Box display="flex" sx={{ p: 4, mt: 2 }}>
        <Stack spacing={{ xs: 4, sm: 2, md: 2 }}>
          <p style={{ whiteSpace: 'nowrap' }}>{LandingPageHeadings.Text2}</p>
          <Button
            id="landingPage-button1"
            color="primary"
            type="submit"
            variant="outlined"
            size="large"
            name="StANDALONE"
            className={classes.button}
            onClick={handleNavigation}
          >
            STANDALONE
          </Button>

          <Button
            id="landingPage-iesButton1"
            color="primary"
            type="submit"
            variant="outlined"
            size="large"
            name="AS PART OF IES"
            className={classes.button}
          >
            AS PART OF IES
          </Button>
          <Button
            id="landingPage-desktopButton"
            color="primary"
            type="submit"
            variant="outlined"
            size="large"
            name="Desktop"
            className={classes.button}
          >
            Desktop
          </Button>
        </Stack>
      </Box>
    </Container>
  )
}

export default LandingPage
