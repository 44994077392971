import React, { useState } from 'react'
import {
  Drawer,
  Card,
  CardContent,
  Typography,
  Avatar,
  Backdrop,
  IconButton,
} from '@mui/material'
import { Box } from '@mui/system'
import ImageWithTick from '../../components/ImageWithTick'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import { makeStyles } from 'tss-react/mui'
import Paper from '@mui/material/Paper'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#e8eaf6',
  },
  header: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  headerInfo: {
    width: '100%',
    padding: '16px 8px',
  },
  logoInfo: {
    display: 'inline-flex',
  },
  qualificationsTable: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    textAlign: 'left',
    marginLeft: 'theme.spacing(1)',
    fontSize: 20,
  },
  tableRow: {
    borderBottom: '1px solid #ccc',
    backgroundColor: '#F5F5F5',
    fontSize: 80,
  },
  tableCell: {
    padding: theme.spacing(1),
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  tableCell1: {
    padding: '16px 0px',
  },
  tableRow1: {
    color: 'orange',
  },
  expired: {
    color: '#ef5350',
  },
  expiringSoon: {
    color: 'orange',
  },
  valid: {
    color: '#4caf50',
  },
  idButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#304ffe',
    color: theme.palette.secondary.contrastText,
    borderRadius: '8px',
    bottom: 0,
    textAlign: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: '20px',
  },
  buttonInfo: {
    textAlign: 'center',
  },
  userInfo: {
    display: 'inline-flex',
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  ml3: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
}))
const data = [
  {
    trainingName: 'Access & Egress (MainPratical)',
    expirationDate: '2024-06-30 13:18:49.06527',
    isExpired: true,
  },
  {
    trainingName: 'Electrical Safety Refresher',
    expirationDate: '2024-09-25 13:18:49.06527',
    isExpired: false,
  },
]

const ProfileWithQual = () => {
  const { classes } = useStyles()
  const [open, setOpen] = useState(true) // State to control drawer visibility
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Box sx={{ display: 'flex' }}>
      {/* Main content here */}
      <Box sx={{ flexGrow: 1 }}>{/* Your main content goes here */}</Box>
      {/* Side component */}
      <Drawer
        anchor="right"
        open={open}
        variant="persistent"
        PaperProps={{
          sx: {
            width: 400,
            backdropFilter: 'blur(8px)', // Blur effect
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Card sx={{ margin: 2 }}>
          <CardContent>
            <ImageWithTick src="/person.jpg" alt="Sample"   
				/>
            <Typography
              variant="body2"
              component="div"
              color="text.Primary"
              justifyContent="center"
              display="flex"
            >
              Name:Izzy Installer
            </Typography>
            <Typography
              variant="body2"
              color="text.Primary"
              justifyContent="center"
              display="flex"
            >
              Company:Kone
            </Typography>
            <Typography
              variant="body2"
              color="text.Primary"
              justifyContent="center"
              display="flex"
            >
              Role:fitter
            </Typography>
            <Typography
              variant="body2"
              color="text.Primary"
              justifyContent="center"
              display="flex"
            >
              Pass ID:123456
            </Typography>
            <Typography
              variant="body2"
              color="text.Primary"
              justifyContent="center"
              display="flex"
            >
              Pass Expiration:12345
            </Typography>
          </CardContent>

          {/* Qualifications */}
          <Table
            sx={{ minWidth: 300, maxWidth: 450 }}
            component={Paper}
            className={classes.qualificationsTable}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  <span className={classes.ml1}>Qualification</span>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <span className={classes.ml1}>Expiration</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((qual, index) => (
                // @ts-ignore

                <TableRow
                  key={index}
                  className={classes.tableRow}
                  style={
                    index % 2
                      ? { background: 'white' }
                      : { background: '#F5F5F5' }
                  }
                >
                  <TableCell className={classes.tableCell}>
                    {qual.trainingName}
                  </TableCell>
                  <TableCell className={classes.tableCell1}>
                    {qual.isExpired ? (
                      <WarningRoundedIcon className={classes.expired} />
                    ) : (
                      <CircleRoundedIcon
                        className={classes.valid}
                        fontSize="small"
                      />
                    )}
                    <span className={classes.ml3}>
                      {' '}
                      {new Date(qual.expirationDate).toLocaleDateString()}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Drawer>
      {/* Blurred background */}
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(10px)',
        }}
        open={open}
      />
    </Box>
  )
}
export default ProfileWithQual
