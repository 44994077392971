//@ts-nocheck
import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Header from '../../components/Header'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import InstallerForm from '../../components/InstallerForm'
import SquareIcon from '@mui/icons-material/Square'
import WarningIcon from '@mui/icons-material/Warning'
import CircleIcon from '@mui/icons-material/Circle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import SubHeader from '../../components/SubHeader'
import AddIcon from '@mui/icons-material/Add'
import AddNewDialog from '../../components/AddNewDialog'
import ProfileWithQual from '../ProfileWithQual'
import { useGetToken } from '../../hooks/useGetToken'
import axios, { AxiosRequestConfig } from 'axios'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

function createData(
  name: string,
  AccessEgress: any,
  ElectricalSafety: any,
  FallPrevention: any,
  HSAwareness: any,
  MaterialHandling: any,
  TAKE2: any,
  TAKE5Global: any,
  UndertheHookLiftingandSlinging: any,
) {
  return {
    name,
    AccessEgress,
    ElectricalSafety,
    FallPrevention,
    HSAwareness,
    MaterialHandling,
    TAKE2,
    TAKE5Global,
    UndertheHookLiftingandSlinging,
  }
}

export default function AdminScreen() {
  const [getToken] = useGetToken()
  const [open, setOpen] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [selectedData, setSelectedData] = React.useState(false)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | null>(null)
  const [openForm, setOpenForm] = React.useState(false)
  const [showForm, setShowForm] = React.useState(false)
  const [companyList, setCompanyList] = React.useState([])
  const [installers, setInstallers] = React.useState([])
  const [fitters, setFitters] = React.useState([])

  const [selectedCompany, setSelectedCompany] = React.useState(null)

  const handleRowClick = (company) => {
    // Toggle selection: if the same company is 4eclicked again, deselect it
    setSelectedCompany(
      selectedCompany && selectedCompany.company_id === company.company_id
        ? null
        : company,
    )
  }
  const toggleForm = () => {
    setShowForm((prev) => !prev)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleRedirect = () => {
    setSelectedData(true)
  }

  const fetchData = async () => {
    const token = await getToken()
    const uri = `https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/installers`
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }

    try {
      const response = await axios.get(uri, config)
      setData(response.data)
      updateFitters(response.data)
      setLoading(false)
    } catch (err) {
      setError('Failed to fetch data')
      setLoading(false)
    }
  }

  function StatusIcon(status: string) {
    if (status === 'yellow') {
      return <SquareIcon style={{ color: 'ffcc00' }} />
    }
    if (status === 'red') {
      return <WarningIcon color="error" />
    }
    return <CircleIcon color="success" />
  }

  const updateFitters = (data: any[]) => {
    const updatedFitters = data.map((installer) => ({
      companyCode: installer.company_id,
      ...createData(
        `${installer.first_name} ${installer.last_name}`,
        StatusIcon('red'), // Replace with actual status values as needed
        StatusIcon('green'),
        StatusIcon('green'),
        StatusIcon('green'),
        StatusIcon('green'),
        StatusIcon('yellow'),
        StatusIcon('yellow'),
        StatusIcon('red'),
      ),
    }))
    setFitters(updatedFitters)
    // Update fitters state
  }
  const handleUpdateInstallers = (data) => {
    setInstallers(data) // Update installers state with new data
    updateFitters(data) // Update fitters with the new data
  }

  const fetchCompanyData = async () => {
    const token = await getToken() // Assuming you have a function to get the token
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }

      const response = await axios.get(
        'https://smp-api-raatest.ies-app-dev.aw.kone.com/v1/company/list',
        config,
      )

      const companies = response.data
      setCompanyList(companies)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  React.useEffect(() => {
    fetchData()
    fetchCompanyData()
  }, [])

  return (
    <>
      {openDialog && (
        <AddNewDialog handleCloseDialog={handleCloseDialog} openDialog />
      )}
      <TableContainer
        component={Paper}
        sx={{
          borderCollapse: 'collapse',
          alignContent: 'center',
          borderBottom: '2px solid #000',
          borderRight: '2px solid #000',
          borderColor: '#E0E0E0',
        }}
      >
        <Header />
        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <SubHeader handleOpenDialog={handleOpenDialog} />
        </div>
        <Table
          sx={{ borderCollapse: 'collapse' }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{ backgroundColor: 'white', border: 'none' }}
              ></TableCell>
              <TableCell
                align="center"
                colSpan={5}
                style={{ backgroundColor: '#AFEEEE', borderColor: '#E0E0E0' }}
              >
                SAFETY
              </TableCell>
              <TableCell
                align="center"
                colSpan={5}
                style={{ backgroundColor: '#ACE1AF', borderColor: '#E0E0E0' }}
              >
                METHOD
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                borderRight: '2px solid #000',
                backgroundColor: '#F5F5F5',
              }}
            >
              <TableCell
                style={{ backgroundColor: 'white', border: 'none' }}
              ></TableCell>

              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                Access & Egress (Main/Practical)
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                Electrical Safety Refresher
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                Fall Prevention
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                H&S Awareness
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                Material Handling
              </TableCell>

              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                TAKE 2
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                TAKE 5 Global
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: '2px solid #000',
                  borderRight: '2px solid #000',
                  borderColor: '#E0E0E0',
                  maxWidth: '70px',
                }}
              >
                Under the Hook Lifting and Slinging
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyList.map((company) => (
              <>
                <TableRow
                  key={company.company_id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    borderBottom: '2px solid #000',
                    borderRight: '2px solid #000',
                    borderColor: '#E0E0E0',
                  }}
                  onClick={() => handleRowClick(company)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: '2px solid #000',
                      borderRight: '2px solid #000',
                      backgroundColor: '#0000FF',
                      color: 'white',
                      borderColor: '#E0E0E0',
                      width: '200px',
                    }}
                  >
                    {company.name}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                      style={{
                        color: 'white',
                        borderColor: '#E0E0E0',
                      }}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: '2px solid #000',
                      borderRight: '2px solid #000',
                      borderColor: '#E0E0E0',
                    }}
                    colSpan={10}
                  >
                    <Collapse
                      in={
                        selectedCompany &&
                        selectedCompany.company_id === company.company_id
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      {selectedCompany &&
                        fitters
                          .filter(
                            (fitter) =>
                              fitter.companyCode ===
                              selectedCompany?.company_id,
                          )
                          .map((fitter) => (
                            <>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        width: '180px',
                                        maxHeight: '5px',
                                      }}
                                      onClick={handleRedirect}
                                    >
                                      <CheckCircleIcon
                                        style={{
                                          color: 'green',
                                          fontSize: 'small',
                                        }}
                                      />
                                      {fitter.name}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '65px',
                                      }}
                                    >
                                      {fitter.AccessEgress}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '75px',
                                      }}
                                      align="center"
                                    >
                                      {fitter.ElectricalSafety}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '85px',
                                      }}
                                    >
                                      {fitter.FallPrevention}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '50px',
                                      }}
                                    >
                                      {fitter.HSAwareness}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '50px',
                                      }}
                                    >
                                      {fitter.MaterialHandling}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '50px',
                                      }}
                                    >
                                      {fitter.TAKE2}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '50px',
                                      }}
                                    >
                                      {fitter.TAKE5Global}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderBottom: '2px solid #000',
                                        borderRight: '2px solid #000',
                                        borderColor: '#E0E0E0',
                                        maxWidth: '50px',
                                      }}
                                    >
                                      {fitter.UndertheHookLiftingandSlinging}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody></TableBody>
                              </Table>
                            </>
                          ))}
                      <TableRow>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenForm(!open)}
                          style={{
                            borderColor: '#E0E0E0',
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <Button color="inherit" onClick={toggleForm}>
                          Add new fitter
                        </Button>
                        <InstallerForm
                          openForm={showForm}
                          onClose={toggleForm}
                          onUpdateInstallers={handleUpdateInstallers}
                        />
                      </TableRow>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}

            <TableRow>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                style={{
                  borderColor: '#E0E0E0',
                }}
              />
              <FormControl fullWidth>
                <InputLabel id="installer-select-label">
                  Add New Supervisor
                </InputLabel>
                <Select
                  labelId="supervisor-select-label"
                  label="Add Supervisor"
                >
                  <MenuItem value={1}>Jukka Karo</MenuItem>
                  <MenuItem value={2}>Antii Lindell</MenuItem>
                  <MenuItem value={3}>Rebekha Ragunan</MenuItem>
                </Select>
              </FormControl>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {selectedData && <ProfileWithQual />}
    </>
  )
}
